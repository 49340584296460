const handleAppearingHeader = function (el) {
  window.dispatchEvent(new Event('scrollingEnd'))
  el.classList.remove('is-sticky')
}

const handleDisappearingHeader = function (el) {
  window.dispatchEvent(new Event('scrollingStart'))
  el.classList.add('is-sticky')
}

const watch = function (prevElement, lastElement, stickyNavigationElement) {
  const headerCallback = function (elements) {
    if (elements[0].isIntersecting === true) {
      handleAppearingHeader(stickyNavigationElement)
    } else {
      handleDisappearingHeader(stickyNavigationElement)
    }
  }

  if ('IntersectionObserver' in window) {
    const headerObserver = new IntersectionObserver(headerCallback, {})
    headerObserver.observe(prevElement)

    const links = stickyNavigationElement.getElementsByClassName('subnav-link')
    const navigation = []

    navigation.push({
      id: prevElement.id,
      target: prevElement,
      clearHash: true
    })

    for (const link of links) {
      const id = link.attributes.href.value.split('#')[1]
      navigation.push({
        id: id,
        link: link,
        target: document.getElementById(id)
      })
    }

    navigation.push({
      id: lastElement.id,
      target: lastElement,
      clearHash: true
    })

    let visibility = {}
    const contentCallback = function (elements) {
      for (const element of elements) {
        if (element.isIntersecting) {
          visibility[element.target.id] = element.intersectionRatio
        } else {
          visibility[element.target.id] = 0
        }
      }

      let largest = -1
      let found
      for (const id in visibility) {
        const el = visibility[id]
        if (el > largest) {
          found = id
          largest = el
        }
      }

      for (const nav of navigation) {
        if (nav.id === found) {
          nav.link && nav.link.classList.add('active')
        } else {
          nav.link && nav.link.classList.remove('active')
        }
      }
    }

    const contentObserver = new IntersectionObserver(contentCallback, {
      root: document,
      threshold: [ 0, 0.25, 0.5, 0.75, 1 ]
    })

    contentObserver.observe(prevElement)

    for (const nav of Object.values(navigation)) {
      if (nav.target) {
        contentObserver.observe(nav.target)
      }
    }

    contentObserver.observe(lastElement)
  }
}

export { watch }
