const stickyNavigation = require('./lib/stickyNavigation')
const AOS = require('aos')


const boot = function (callback) {
  if (document.readyState === 'loading') {
    document.addEventListener('DOMContentLoaded', callback)
  } else {
    callback()
  }
}

boot(function () {
  const header = document.getElementById('fullscreen-header')
  const lastElement = document.getElementById('newsletter')
  const subNavigation = document.getElementById('sub-navigation')

  if (header && lastElement && subNavigation) {
    stickyNavigation.watch(header, lastElement, subNavigation)
  }

  AOS.init({once: true})
})
